import React, { Component } from 'react';
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src="https://res.cloudinary.com/beora/image/upload/c_scale,h_280/v1554531272/jorongoio/gopherbw.png" alt="logo" />
          <p className="Slogan">
            We are a user group for people interested in learning, collaborating, and teaching about the Go programming language.
          </p>
          <p>
            <a href="https://github.com/jorongo"><img src="https://res.cloudinary.com/beora/image/upload/c_scale,h_40/v1554531763/jorongoio/GitHub-Mark-Light-120px-plus.png" alt="github.com" /></a>
          </p>
        </header>
      </div>
    );
  }
}

export default App;
